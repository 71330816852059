import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import { ShopWidget } from './components/ShopWidget';

declare global {
    interface OpenTicketWindow {
        OtShopWidget?: ShopWidget;
    }

    interface Window {
        OpenTicket: OpenTicketWindow;
        OtShopSettings: CustomShopSettingsClient
    }
}

const shopWidget = new ShopWidget();

Object.defineProperty(window, 'OtShopWidget', {
    get: () => shopWidget,
    set: () => {
        console.warn('Re-setting [OtShopWidget] is not allowed, ignoring.');
    },
});

Object.assign(window, { OpenTicket: window.OpenTicket || {} });

Object.defineProperty(window.OpenTicket, 'ShopWidget', {
    get: () => shopWidget,
    set: () => {
        console.warn('Re-setting [ShopWidget] is not allowed, ignoring.');
    },
});

const shopSettings = new CustomShopSettingsClient();

Object.defineProperty(window, 'OtShopSettings', {
    get: () => shopSettings,
    set: () => {
        console.warn('Re-setting [OtShopSettings] is not allowed, ignoring.');
    },
});

export {
    ShopWidget,
};
