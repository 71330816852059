type Tuple<T, K extends keyof T = keyof T> = [ K, T[K] ];

export function createElement<
    K extends keyof HTMLElementTagNameMap,
    C extends Array<string | HTMLElementTagNameMap[keyof HTMLElementTagNameMap] | SVGSVGElement | Comment>,
>(
    tag: K,
    data: Partial<HTMLElementTagNameMap[K]> = {},
    classNames: string | string[] = [],
    ...children: C
): HTMLElementTagNameMap[K] {
    const el = document.createElement(tag);

    (Object.entries(data) as Array<Tuple<HTMLElementTagNameMap[K]>>)
        .forEach(([ key, value ]) => {
            el[key] = value;
        });

    el.classList.add(...([] as string[]).concat(classNames));

    el.dataset.otShopWidget = 'v1';

    children.forEach((c) => {
        if (typeof c === 'string') {
            el.appendChild(document.createTextNode(c));
        } else {
            el.appendChild(c);
        }
    });

    return el;
}
