import type {
    LocaleKeys, Localization, NestedTranslations, Translation,
} from '@openticket/lib-localization';

function propBySlugArray(values: NestedTranslations | Translation, slugArray: string[]): string | undefined {
    const next = slugArray.shift();
    if (next) {
        if (slugArray.length > 0) {
            if (next && typeof values !== 'string' && values[next]) {
                return propBySlugArray(values[next], slugArray);
            }
        } else if (typeof values !== 'string' && typeof values[next] === 'string') {
            return values[next];
        }
    }
    return undefined;
}

/**
 * Returns the translation of the supplied slug given from the supplied localization object with proper fallback
 * for the given locale
 * The fallback language is `en_US` and if non-existing `en_GB` (like Vue-i18n does it)
 * @param slug
 * @param localization
 * @param locale
 */
export function getTranslationWithFallback(slug: string, localization: Localization, locale: string): string {
    let res: string | undefined;

    // Since typescript cannot check if a string is part of a union, we just do a simple sanity check on the supplied locale
    const checkLocale = (localeToBeChecked: string): localeToBeChecked is LocaleKeys => (localeToBeChecked.length === 5 && [ '-', '_' ].includes(localeToBeChecked[2])) || localeToBeChecked.length === 2;

    if (checkLocale(locale)) {
        const messages = localization.getMessagesFor(locale);
        res = propBySlugArray(messages, slug.split('.'));
    }

    if (!res) {
        // Mimicking vue-localization fallback: https://vue-i18n.intlify.dev/guide/essentials/fallback.html
        res = propBySlugArray(localization.getMessagesFor('en_US'), slug.split('.'));
        if (!res) {
            res = propBySlugArray(localization.getMessagesFor('en_GB'), slug.split('.'));
        }
    }
    return res || slug;
}
