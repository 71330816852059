export type CartCallback = (tickets: { [guid: string]: { selected: number } }) => void;
export type OrderTotalCallback = (totalPrice: string) => void;
export type OrderTotalRawCallback = (totalPrice: number, currency: string) => void;
export type WidgetToggledCallback = (opened: boolean) => void;
export type WidgetStatusCallback = (
    status: typeof WidgetStatus[keyof typeof WidgetStatus],
    numberOfTickets?: number,
    totalPriceCents?: number
) => void;

export const WidgetStatus = {
    Loading: 'loading',
    Closed: 'closed',
    Opened: 'opened',
} as const;

export interface PartialCartData {
    tickets: { [guid: string]: { count: number; }; };
    products: { [guid: string]: { count: number; }; };

    checkout_details: { total_price: number; };
}
