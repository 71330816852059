/**
 * Converts the given value to a boolean when it is either:
 * - A boolean
 * - A number (0 - 1 (and all other numbers)
 * - A string ('true' - 'false', case-insensitive, '0' - '1')
 * Returns false or the provided fallback if it could not be converted
 * @param inValue
 * @param fallback
 */
export function toBoolean<T>(inValue: unknown, fallback?: T): T | boolean {
    if (typeof inValue === 'boolean') {
        return inValue;
    }

    if (typeof inValue === 'number') {
        return !!inValue;
    }

    if (typeof inValue === 'string') {
        if (inValue.toLowerCase() === 'true' || inValue === '1') {
            return true;
        } if (inValue.toLowerCase() === 'false' || inValue === '0') {
            return false;
        }
    }

    if (fallback === undefined) {
        return false;
    }
    return fallback;
}
